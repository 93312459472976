import { FC, MouseEvent, useCallback, useEffect, useState } from 'react';
import { NotificationIcon, RefreshIcon } from '../../../utils/icons';
import {
  Badge,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover,
  Theme,
  Tooltip,
  Typography,
  ListItemButton,
  ToggleButtonGroup,
  ToggleButton,
  Tabs,
  Tab,
} from '@mui/material';
import { NotificationItem } from './notification-item';
import { NotificationType, useNotifications } from '../../../hooks/notification/useNotifications';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useReadNotifications } from '../../../hooks/notification/useReadNotifications';
import { useAuth } from '../../../contexts';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import CircularLoading from '../common/circular-loading';
import { arrayFromEnum } from '../../../utils/enum';
import { useNotificationCount } from '../../../hooks/notification/useNotificationCount';

const useStyles = makeStyles((theme: Theme) => ({
  widgetPaper: {
    width: 500,
    maxWidth: 500,
    maxHeight: 600,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      maxHeight: '70vh',
      maxWidth: '90vw',
      minWidth: 0,
    },
  },
  widgetList: {
    flexGrow: 1,
    minHeight: 0,
    overflowY: 'auto',
  },
  notificationSectionBadge: {
    borderRadius: '100px',
    backgroundColor: theme.palette.primary.main,
    minWidth: 20,
    minHeight: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    paddingLeft: '4px',
    paddingRight: '4px',
  },
}));

type NotificationTab = 'unread' | 'all';

type NotificationTypesCounters = {
  totalOther: number;
  totalRequest: number;
  totalReport: number;
  totalChat: number;
}


export const NotificationMenu: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [bellCounter, setBellCounter] = useState<number>();

  const { counters, loading: countersLoading, reloadNotificationCount } = useNotificationCount();
  const [typeCounters, setTypeCounters] = useState<NotificationTypesCounters>();

  const [notificationLimit, setNotificationLimit] = useState<number>(10);
  const [onlyUnread, setOnlyUnread] = useState<NotificationTab>('unread');
  const [notificationType, setNotificationType] = useState<NotificationType>(NotificationType.Request);

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    tab: NotificationTab,
  ) => {
    if (tab) {
      setOnlyUnread(tab);
    }
  };

  const { notifications, paginationInfo, totalUnreadNotifications, loading, refetch, subscribe, notificationsToShow } = useNotifications({
    onlyUnread: onlyUnread === 'unread' ? true : false,
    skip: 0,
    limit: notificationLimit,
    notificationType,
  });

  useEffect(() => {   //s ets bell badge only if totalUnreadNotifications is actually changed
    if(totalUnreadNotifications) {
      setBellCounter(totalUnreadNotifications)
    }
  }, [totalUnreadNotifications]);

  const { readMany, loading: readManyLoading } = useReadNotifications();
  const { ready } = useAuth();

  useEffect(() => {
    if (ready) {
      subscribe();
    }
  }, [subscribe, ready]);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setNotificationLimit(10);
    setAnchorEl(null);
  };

  const markAsRead = useCallback(async () => {
    const unreadIds = notifications?.filter(n => !n.readAt).map(n => n.id);
    if (unreadIds?.length) {
      await readMany(unreadIds);
      reloadNotificationCount();
    }
  }, [readMany, reloadNotificationCount, notifications]);

  useEffect(() => {
    if(counters && !countersLoading) {
      setTypeCounters({
        totalRequest: counters?.totalRequest,
        totalChat: counters?.totalChat,
        totalReport: counters?.totalReport,
        totalOther: counters?.totalOther,
      })
    }
  }, [counters, countersLoading]);

  const getTypeCounter = useCallback((type: NotificationType) => {
    switch(type) {
      case NotificationType.Request:
        return typeCounters?.totalRequest;
      case NotificationType.Chat:
        return typeCounters?.totalChat;
      case NotificationType.Report:
        return typeCounters?.totalReport;
      case NotificationType.Others:
        return typeCounters?.totalOther;
    }
  },[typeCounters]);

  return (
    <>
      <IconButton color="inherit" onClick={handleClick}>
        <Badge badgeContent={bellCounter} color="secondary" max={999}>
          <NotificationIcon />
        </Badge>
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        //keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          className: classes.widgetPaper,
        }}
      >
        <div className={classes.widgetList}>
          <Box pl={2} pr={1} py={1} display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">{t('notifications')}</Typography>
            <Box>
              <ToggleButtonGroup
                color="primary"
                value={onlyUnread}
                exclusive
                onChange={handleChange}
                sx={{ height: 35 }}
              >
                <ToggleButton value="all">Tutte</ToggleButton>
                <ToggleButton value="unread">Non lette</ToggleButton>
              </ToggleButtonGroup>
            </Box>
            <Box display="flex">
              <Tooltip title={`${t('mark-all-as-read')}`}>
                <IconButton disabled={readManyLoading} onClick={markAsRead}>
                  <DoneAllIcon fontSize="small"/>
                </IconButton>
              </Tooltip>
              <Tooltip title={`${t('refresh')}`}>
                <IconButton onClick={() => refetch()}>
                  <RefreshIcon/>
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={notificationType} onChange={(e, value) => setNotificationType(value)} >
              {
                arrayFromEnum(NotificationType)
                .filter(type => type !== NotificationType.All)
                .map(type =>
                  <Tab
                    key={type}
                    value={type}
                    label={
                      <Box display="flex" alignItems="center">
                        <Typography variant="button">{t(`notification:${type}`)}</Typography>
                        {
                          getTypeCounter(type)
                          ? <Box className={classes.notificationSectionBadge} sx={{ ml: 1 }}>
                            { getTypeCounter(type) }
                          </Box>
                          : <></>
                        }
                      </Box>
                    }
                  />,
                )
              }
            </Tabs>
          </Box>
          <Box sx={{ maxHeight: '500px', overflowY: 'auto' }}>
            {
              loading
              ? <CircularLoading minHeight={500} />
              : <>
                <List disablePadding>
                  {
                    notificationsToShow.length > 0
                    ? notificationsToShow.map((not, i) => (
                      <NotificationItem
                        loading={false}
                        notification={not}
                        key={not.id}
                        onClose={handleClose}
                        reloadNotificationCount={reloadNotificationCount}
                      />
                    ))
                    : <ListItem sx={{ textAlign: 'center', py: 2 }}>
                      <ListItemText primary={t('no-notifications')} />
                    </ListItem>
                  }
                </List>
                {
                  paginationInfo?.hasMore &&
                  <ListItemButton onClick={() => setNotificationLimit(notificationLimit + 10)}>
                    <Box textAlign="center" width="100%" py={0.5}>
                      <Typography variant="button" color="primary">Mostra di più</Typography>
                    </Box>
                  </ListItemButton>
                }
              </>
            }
          </Box>
        </div>
      </Popover>
    </>
  )
}
